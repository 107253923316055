import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import useMounted from "../../utils/hooks/useMounted"
import useScrollBox from "../../utils/hooks/useScrollBox"
import parseFoodNameToPath from "../../utils/parseFoodNameToPath"

const isOverflown = (element: React.MutableRefObject<null | HTMLElement>) => {
  if (element.current) {
    return element.current.scrollWidth > element.current.clientWidth
  }
  return false
}

interface FoodGroupTileContentsProps {
  groupItems: Array<string>
  ulRef: React.MutableRefObject<HTMLUListElement | null>
  containerRef: React.MutableRefObject<HTMLDivElement | null>
  onScrollEnd: () => void
  setOverflowing: React.Dispatch<React.SetStateAction<boolean>>
}

const FoodGroupTileContents: React.FC<FoodGroupTileContentsProps> = ({
  groupItems,
  ulRef,
  containerRef,
  onScrollEnd,
  setOverflowing,
}) => {
  const [rows, setRows] = useState(3)
  const mounted = useMounted()

  // Calculate number of rows given number of items and size of container
  useEffect(() => {
    const calculateRows = () => {
      if (containerRef.current) {
        const maxColumns = Math.floor(containerRef.current.clientWidth / 150)
        const minRows = Math.ceil(groupItems.length / maxColumns)
        setRows(Math.min(3, minRows < 1 ? 1 : minRows))
      }
    }

    calculateRows()
    window.addEventListener("resize", calculateRows)

    return function cleanup() {
      window.removeEventListener("resize", calculateRows)
    }
  }, [groupItems])

  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current) {
        setOverflowing(isOverflown(containerRef))
      }
    }

    setTimeout(() => checkOverflow(), 500)
    window.addEventListener("resize", checkOverflow)

    return function cleanup() {
      window.removeEventListener("resize", checkOverflow)
    }
  }, [mounted, rows])

  useScrollBox(containerRef, onScrollEnd)

  useEffect(() => {}, [containerRef])

  // 25px margin + 33px for each row
  const maxHeight = 25 + rows * 33

  return (
    <div className="relative flex items-center w-full px-6">
      <div
        className={`w-full relative overflow-x-auto hide-scrollbar`}
        ref={containerRef}
      >
        <ul
          className={`inline-flex flex-col flex-wrap pb-3 relative`}
          style={{
            maxHeight: maxHeight,
          }}
          ref={ulRef}
        >
          {groupItems.map((item, idx) => {
            return (
              <li
                key={item + idx}
                style={{
                  width: "150px",
                  maxWidth: "150px",
                  userSelect: "none",
                }}
                className="py-1 flex-grow-0"
                title={item}
                aria-label={item}
              >
                <Link
                  to={`/encyclopedia/foods/${parseFoodNameToPath(item)}`}
                  className="hover:underline select-none text-grey-700 flex items-center text-base"
                >
                  {clipText(item, 14)}
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
export default FoodGroupTileContents

const clipText = (text: string, charLimit: number) => {
  if (text.length > charLimit) {
    return text.slice(0, charLimit - 2) + "..."
  }
  return text
}
