import { useEffect, useRef } from "react"
import useMounted from "./useMounted"

const useScrollBox = (
  scrollRef: React.MutableRefObject<null | HTMLElement>,
  customScrollEndCallback?: () => void
) => {
  const clickStartX = useRef<undefined | number>()
  const scrollStartX = useRef<undefined | number>()
  const velocity = useRef(0)
  const mounted = useMounted()

  const refCurrent = scrollRef.current

  const handleDragStart = (e: MouseEvent) => {
    e.preventDefault()
    if (refCurrent) {
      clickStartX.current = e.screenX
      scrollStartX.current = scrollRef.current!.scrollLeft
      document.documentElement.addEventListener("mousemove", handleDragMove)
      document.documentElement.addEventListener("mouseup", handleDragEnd)
    }
  }
  const handleDragMove = (e: MouseEvent) => {
    if (
      clickStartX.current !== undefined &&
      scrollStartX.current !== undefined
    ) {
      scrollRef.current!.style.pointerEvents = "none"
      updateScrollPos(e)
    }
  }
  const handleDragEnd = () => {
    if (refCurrent) {
      document.documentElement.removeEventListener("mousemove", handleDragMove)
      document.documentElement.removeEventListener("onmouseup", handleDragEnd)
      clickStartX.current = undefined
      scrollStartX.current = undefined
      velocity.current = velocity.current
      refCurrent.style.pointerEvents = "auto"
      finish()
    }
  }
  const updateScrollPos = (e: MouseEvent) => {
    if (refCurrent) {
      const oldScrollLeft = refCurrent!.scrollLeft

      const touchDelta = clickStartX.current! - e.screenX
      refCurrent!.scrollLeft = scrollStartX.current! + touchDelta

      velocity.current = refCurrent!.scrollLeft - oldScrollLeft
    }
  }
  const limitSpeed = (speed: number) => {
    const maxScrollSpeed = 40

    if (Math.abs(speed) > maxScrollSpeed) {
      return maxScrollSpeed * (speed < 0 ? -1 : 1)
    }
    return speed
  }

  const update = () => {
    if (
      clickStartX.current === undefined &&
      scrollStartX.current === undefined
    ) {
      const defaultDrag = 0.96
      velocity.current = velocity.current * defaultDrag

      if (refCurrent) {
        refCurrent.scrollLeft = Math.round(
          refCurrent.scrollLeft + velocity.current
        )
      }

      if (Math.floor(Math.abs(velocity.current)) !== 0) {
        requestAnimationFrame(update)
      } else {
        if (customScrollEndCallback) customScrollEndCallback()
      }
    }
  }

  const finish = () => {
    velocity.current = limitSpeed(velocity.current)

    if (velocity.current !== 0) {
      requestAnimationFrame(update)
    } else {
      if (customScrollEndCallback) customScrollEndCallback()
    }
  }

  useEffect(() => {
    if (refCurrent) {
      if (refCurrent.ontouchstart === undefined) {
        refCurrent.addEventListener("mousedown", handleDragStart)
        // document.documentElement.addEventListener("mousemove", handleDragMove)
        // document.documentElement.addEventListener("mouseup", handleDragEnd)
      }
    }

    return () => {
      if (refCurrent) {
        refCurrent.removeEventListener("onmousedown", handleDragStart)
      }
      document.documentElement.removeEventListener("mousemove", handleDragMove)
      document.documentElement.removeEventListener("onmouseup", handleDragEnd)
    }
  }, [mounted])
}

export default useScrollBox
