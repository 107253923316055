import { graphql } from "gatsby"
import React, { useState } from "react"
import FoodGroupTile from "../components/Encyclopedia/FoodGroupTile"
import Footer from "../components/QuickSearch/Footer"
import SEO from "../components/seo"
import { metadata } from "../data/foodsMetadata.json"

const sortFoodGroups = () => {
  const foodGroups = {}

  metadata.forEach(foodObj => {
    let foodType = foodObj.type
    if (foodGroups[foodType] !== undefined) {
      foodGroups[foodObj.type].push(foodObj.name)
    } else {
      foodGroups[foodType] = []
    }
  })

  return foodGroups
}

const foodGroups = sortFoodGroups()
const foodTypes = Object.keys(foodGroups)

const FoodEncyclopediaPage = () => {
  const [_, setOpenGroup] = useState<null | string>(null)

  const handleOpenGroup = React.useCallback((group: string) => {
    setOpenGroup(group)
  }, [])
  const handleCloseGroup = React.useCallback(() => setOpenGroup(null), [])

  return (
    <>
      <SEO
        title="Encyclopedia"
        description="Our Encyclopedia hosts entries for various foods and nutrients.
              Each with their own key highlights."
      />

      <div
        className="z-10 relative w-3xl mx-auto pt-6 px-6"
        style={{ maxWidth: 950 }}
      >
        <div className="mb-12">
          <div className="rounded-md">
            {foodTypes.map((foodType, idx) => {
              const position =
                idx === 0
                  ? "start"
                  : idx === foodTypes.length - 1
                  ? "end"
                  : "middle"
              return (
                <FoodGroupTile
                  key={foodType}
                  position={position}
                  groupName={foodType}
                  groupItems={foodGroups[foodType]}
                  setOpenGroup={handleOpenGroup}
                  closeGroup={handleCloseGroup}
                  open={true}
                />
              )
            })}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
export default FoodEncyclopediaPage

export const pageQuery = graphql`
  query {
    foodBannerImage: file(relativePath: { eq: "headerImageBlurred2.jpg" }) {
      childImageSharp {
        fixed(width: 1968) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
