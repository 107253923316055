import React from "react"
import ArrowLeft from "../../images/svgs/buttons/chevronLeft.svg"
import ArrowRight from "../../images/svgs/buttons/chevronRight.svg"

interface ChevronButtonProps {
  disabled: boolean
  onClick: () => void
  direction: "left" | "right"
}

const ChevronButton: React.FC<ChevronButtonProps> = ({
  disabled,
  onClick,
  direction,
}) => {
  return (
    <button
      onClick={() => onClick()}
      className={`group inline-flex focus-visible mr-1 rounded-full bg-grey-100 w-6 h-6 justify-center items-center ${
        disabled ? `cursor-default` : ``
      }`}
      disabled={disabled}
    >
      {direction === "left" ? (
        <ArrowLeft
          className={`fill-current transition duration-300 ${
            disabled
              ? `text-grey-200`
              : `text-grey-500 group-hover:text-grey-700`
          }`}
        />
      ) : (
        <ArrowRight
          className={`fill-current transition duration-300 ${
            disabled
              ? `text-grey-200`
              : `text-grey-500 group-hover:text-grey-700`
          }`}
        />
      )}
    </button>
  )
}
export default ChevronButton
