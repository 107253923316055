import React, { useRef, useState } from "react"
import useBodyScroll from "../../utils/hooks/useBodyScroll"
import ChevronButton from "../CoreUI/ChevronButton"
import FoodIcon from "../shared/FoodIcon"
import FoodGroupTileContents from "./FoodGroupTileContents"


const textColorMap = {
  legume: "#A27D47",
  fruit: "#CE4A62",
  grain: "#9C8230",
  vegetable: "#CB6F28",
  seed: "#8C9427",
  nut: "#90582B",
  fungus: "#919563",
}

const pluralMap = {
  fungus: "fungi",
}

interface FoodGroupTileProps {
  position: "start" | "middle" | "end"
  groupName: string
  groupItems: Array<string>
  setOpenGroup: (group: string) => void
  closeGroup: () => void
  open: boolean
}

const FoodGroupTile: React.FC<FoodGroupTileProps> = ({
  groupName,
  groupItems,
  open,
}) => {
  const ulRef = useRef<HTMLUListElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [listOverflowing, setListOverflowing] = useState(false)
  const [pageLeftDisabled, setPageLeftDisabled] = useState(true)
  const [pageRightDisabled, setPageRightDisabled] = useState(false)

  const textColor = textColorMap[groupName.toLowerCase()] || "black"

  useBodyScroll()

  const handlePageLeft = () => {
    if (containerRef.current && ulRef.current) {
      pageLeft(ulRef, containerRef)
      disabledButtonsCheck()
    }
  }

  const handlePageRight = () => {
    if (containerRef.current && ulRef.current) {
      pageRight(ulRef, containerRef)
      disabledButtonsCheck()
    }
  }

  const disabledButtonsCheck = () => {
    if (containerRef.current && ulRef.current) {
      setPageLeftDisabled(containerRef.current.scrollLeft === 0)
      setPageRightDisabled(
        containerRef.current?.scrollLeft ===
          containerRef.current?.scrollWidth - containerRef.current?.clientWidth
      )
    }
  }

  return (
    <div
      className={`relative bg-white shadow border-grey-150 border flex-0 transition ease-in-ease-out duration-150 box-border mb-3 rounded-lg ${
        open ? `` : ``
      }`}
      style={{
        overflowAnchor: "none",
      }}
    >
      <div className="flex items-center justify-between mx-5">
        <div className="flex items-center">
          <FoodIcon foodType={groupName.toLowerCase()} className="mr-4" />
          <h3 className="text-sm font-bold my-4" style={{ color: textColor }}>
            {pluralMap[groupName.toLowerCase()]
              ? pluralMap[groupName.toLowerCase()].toUpperCase()
              : `${groupName.toUpperCase()}S`}
          </h3>
          <div className="bg-grey-200 rounded-full h-1 w-1 mx-5" />
          <h3 className="text-grey-400 text-sm">{groupItems.length} items</h3>
        </div>
        {listOverflowing ? (
          <div>
            <ChevronButton
              onClick={handlePageLeft}
              disabled={pageLeftDisabled}
              direction="left"
            />
            <span className="w-2" />
            <ChevronButton
              onClick={handlePageRight}
              disabled={pageRightDisabled}
              direction="right"
            />
          </div>
        ) : null}
      </div>
      <FoodGroupTileContents
        groupItems={groupItems}
        ulRef={ulRef}
        containerRef={containerRef}
        onScrollEnd={disabledButtonsCheck}
        setOverflowing={setListOverflowing}
      />
    </div>
  )
}
// export default React.memo(FoodGroupTile)
export default FoodGroupTile

const pageRight = (
  ulRef: React.MutableRefObject<HTMLUListElement | null>,
  containerRef: React.MutableRefObject<HTMLDivElement | null>
) => {
  // Get children
  if (ulRef.current && containerRef.current) {
    const children = ulRef.current?.children

    const scrollLeft = containerRef.current.scrollLeft
    const containerWidth = containerRef.current.clientWidth

    let childToScrollTo = null

    for (let i = 0; i < children?.length; i++) {
      const child = children[i] as HTMLElement
      childToScrollTo = child
      if (child.offsetLeft + child.clientWidth > scrollLeft + containerWidth) {
        break
      }
    }

    if (childToScrollTo) {
      // 20px offset for absolute positioned chevron button
      containerRef.current.scrollLeft = childToScrollTo.offsetLeft - 25
    }
  }
}

const pageLeft = (
  ulRef: React.MutableRefObject<HTMLUListElement | null>,
  containerRef: React.MutableRefObject<HTMLDivElement | null>
) => {
  if (ulRef.current && containerRef.current) {
    const children = ulRef.current?.children

    const scrollLeft = containerRef.current.scrollLeft
    const containerWidth = containerRef.current.clientWidth

    let childToScrollTo = null

    for (let i = children.length - 1; i >= 0; i--) {
      const child = children[i] as HTMLElement
      childToScrollTo = child
      if (child.offsetLeft - child.clientWidth < scrollLeft - containerWidth) {
        break
      }
    }

    if (childToScrollTo) {
      // 20px offset for absolute positioned chevron button
      containerRef.current.scrollLeft = childToScrollTo.offsetLeft - 25
    }
  }
}
